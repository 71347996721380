import $ from 'jquery'
window.jQuery = $
window.$ = $
require('jquery-datetimepicker')

// Below code used for DateTimePicker plugin warning suppression
$.event.special.touchstart = {
  setup: function (_, ns, handle) {
    if (ns.includes('noPreventDefault')) {
      this.addEventListener('touchstart', handle, { passive: false })
    } else {
      this.addEventListener('touchstart', handle, { passive: true })
    }
  }
}
$.event.special.touchmove = {
  setup: function (_, ns, handle) {
    if (ns.includes('noPreventDefault')) {
      this.addEventListener('touchmove', handle, { passive: false })
    } else {
      this.addEventListener('touchmove', handle, { passive: true })
    }
  }
}
$.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    if (ns.includes('noPreventDefault')) {
      this.addEventListener('mousewheel', handle, { passive: false })
    } else {
      this.addEventListener('mousewheel', handle, { passive: true })
    }
  }
}
